<style scoped>
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">收款码配置</div>
      <el-form :inline="true" size="mini">
        <el-form-item v-if="this.sa_admin.role == 2" label="码队选择:">
          <el-select size="mini" v-model="p.mchId" @click.native="getMchCode" @change="f5" v-el-select-loadmore="loadmore">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in selectList" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="min-width: 0px">
          <el-button type="primary" v-if="this.sa_admin.role == 2" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
          <el-button type="primary" v-if="this.sa_admin.role == 3" icon="el-icon-plus" @click="add()">添加收款码</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="商户名" prop="shopName" min-width="150px"></el-table-column>
        <!-- <el-table-column label="商铺id" prop="shopId" min-width="100px"></el-table-column> -->
        <el-table-column label="商户编号" prop="merchantNo" min-width="100px"></el-table-column>
        <el-table-column label="类型" min-width="110px">
          <template slot-scope="s">
            <el-tag v-if="s.row.type == 1" type="success" effect="dark" size="mini">拉卡拉收款码</el-tag>
            <el-tag v-if="s.row.type == 2" type="success" effect="dark" size="mini">微信赞赏码</el-tag>
            <el-tag v-if="s.row.type == 3" type="success" effect="dark" size="mini">微信个码</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" min-width="80px">
        </el-table-column>
        <el-table-column label="收款状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949" @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" min-width="150px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.zrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" min-width="150px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日成功率" min-width="150px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrcgl
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshRate(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="限额" min-width="100px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks" :formatter="remarksFormat">
        </el-table-column>
        <el-table-column label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-link" @click.native="testOrder(s.row)">测试订单</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="del(s.row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <AddKfCodeMch ref="person-config"></AddKfCodeMch>
  </div>
</template>

<script>
import AddKfCodeMch from "./add-person-config.vue";
export default {
  components: { AddKfCodeMch },
  data() {
    return {
      p: {
        // 查询参数
        mchId: null,
        page: 1,
        limit: 10,
      },
      dataCount: 1422,
      dataList: [],
      selectList: [],
      conditionsKey: 0,
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v1/person-config/queryAllConfig",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      } else {
        this.p.mchId = this.sa_admin.mchId;
        defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v1/person-config/queryAllConfig",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      }
    },
    getMchCode: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 0,
        msg: null,
      };
      this.sa.ajax(
        "/v2/admin/queryMerchantNickName/3",
        function (res) {
          this.selectList = res.data; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    testOrder: function (data) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            id: data.id,
            amount: value,
          };
          var defaultCfg = {
            type: "get",
          };
          self.sa.ajax(
            "/v1/person-config/payTest",
            p1,
            function (res) {
              self.sa.showImageWithText(
                this.sa.cfg.api_url +
                "/v1/index/enQrcode?url=" +
                encodeURIComponent(res.data),
                "600px",
                "330px", res.data
              );
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    del: function (row) {
      var defaultCfg = {
        type: "delete",
      };
      this.sa.ajax(
        "/v1/person-config/delete/" + row.id,
        function () {
          this.sa.ok("删除成功");
          this.f5();
        }.bind(this),
        defaultCfg
      );
    },
    // 查看
    yesterDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.merchantNo, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountYesterday",
        p2,
        function (res) {
          data.zrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.merchantNo, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountToDay",
        p2,
        function (res) {
          data.jrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshRate: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.merchantNo, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/dataAnalysisBySubMchIdToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrcgl = res.data + "%";
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    /** 展示用户列表页面 **/
    add: function () {
      this.$refs["person-config"].open(0);
    },
    update: function (data) {
      this.$refs["person-config"].open(data);
    },
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改收款商户状态",
        function () {
          var defaultCfg = {
            type: "get", //是否是请求体请求
          };
          var p = {
            status: data.status, //是否是请求体请求
          };
          var params = data;
          this.sa.ajax(
            "/v1/person-config/setStatus/" + data.id, p,
            function () {
              this.sa.ok("修改成功");
              params.status = params.status == 1 ? 0 : 1;
            }.bind(this), defaultCfg
          );
        }.bind(this)
      );
    },
    remarksFormat(row) {
      if (row.remarks == "" || row.remarks == null) {
        return "无备注";
      } else {
        return row.remarks;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    loadmore() {
      // this.p.page++;
      // this.getMchCode();
    },
  },
  created: function () {
    this.f5();
    this.getMchCode();
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>

<style>
</style>
