<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '添加收款二维码' : '更新收款二维码'" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" label-width="150px">
          <el-form-item label="商户名称:">
            <el-input v-model="m.shopName" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="商户编号:">
            <el-input v-model="m.merchantNo" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="收款码类型:">
            <el-select v-model="m.type">
              <el-option label="拉卡拉" :value="1"></el-option>
              <el-option label="微信赞赏码" :value="2"></el-option>
              <el-option label="微信个码" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收款二维码:" v-if="m.type !=2">
            <el-upload :limit="1" class="upload-demo" drag action="http://openapi.jingyihai.top/v1/index/deQrcode" :on-success="handleImgSuccess">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb(赞不支持小程序码)</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="收款二维码地址:" v-if="m.type !=2">
            <el-input v-model="m.qrUrl" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="二维码图片:" v-if="m.type ==2">
            <img :src="'data:image/png;base64,'+m.qrUrl" alt="图片未上传" />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="m.remarks" style="width: 350px"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      loginid: 0,
      url: "",
      zftlist: [],
      timer: null,
      m: {
        shopName: "", // 从菜单配置文件里传递过来的参数
        shopId: "",
        limits: 5000,
        status: 0,
        qrUrl: "",
        type: 1,
        merchantNo: "",
        remarks: ""
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.shopName = data.shopName;
        this.m.shopId = data.mhHost;
        this.m.limits = data.limits;
        this.m.type = data.type;
        this.m.qrUrl = data.qrUrl;
        this.m.remarks = data.remarks;
        this.m.merchantNo = data.merchantNo;
        this.m.status = data.status;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    handleImgSuccess: function (res) {
      this.m.qrUrl = res.data;
      console.log(this.m.qrUrl);
    },
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        this.sa.ajax(
          "/v1/person-config/save",
          this.m,
          function () {
            self.sa.alert("添加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              // self.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/person-config/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      this.m.qrUrl = "";
      this.m.type = 1;
      this.m.limits = 5000;
      this.m.status = 0;
      this.m.remarks = "";
      this.m.shopName = "";
      this.m.shopId = "";
      this.m.merchantNo = "";
    },
  },
  created: function () {
  },
};
</script>
